import dropdownOptions from '../../util/dropdownOptions';
import {
    clientTypeId,
    tipoIdentificacionComercial,
    tipoIdentificacionPersonal,
    preferredBranchId,
    leadChannel,
} from '../commons';


const form = {
    title: "Crear Caso",
    displayName: 'CaseIntake',
    page: 'case-intake',
    hasSaveProgressButton: false
};


const comercialSchema = {
    title: "Información Inicial Cliente Empresa",
    type: "object",
    properties: {
        "nombreEmpresa": {
            title: "Nombre Empresa",
            type: "string",
        },
        "actividadPrincipal": {
            title: "Actividad Principal",
            type: "string",
            enum: dropdownOptions.actividades,
        },
        tipoIdentificacionComercial,
        "nombreContacto": {
            title: "Nombre Contacto",
            type: "string",
        },
        "apellidoContacto": {
            title: "Apellido Contacto",
            type: "string",
        },
        "posicionContacto": {
            title: "Posición Contacto",
            type: "string",
        },
        "celular": {
            title: "Celular",
            type: "string",
            format: "phone-rd",
        },
        "telefono": {
            title: "Teléfono",
            type: "string",
            format: "phone-rd",
        },
        "email": {
            title: "Email",
            type: "string",
            format: "email",
        },
        "comentarios": leadChannel
    },
    required: ["nombreEmpresa", "actividadPrincipal", "tipoIdentificacionComercial",
        "nombreContacto", "apellidoContacto",
        "posicionContacto", "celular", "email"
    ],
    dependencies: {
        "tipoIdentificacionComercial": {
            oneOf: [
                {
                    properties: {
                        "tipoIdentificacionComercial": {
                            const: 1,
                        },
                        "numIdentificacionCedula": {
                            title: "No. de identificación Cédula",
                            type: "string",
                        },
                    },
                    required: ["numIdentificacionCedula"]
                },
                {
                    properties: {
                        "tipoIdentificacionComercial": {
                            const: 4,
                        },
                        "numIdentificacionRNC": {
                            title: "No. de identificación RNC",
                            type: "string",
                            minLength: 9,
                            maxLength: 9,
                        },
                    },
                    required: ["numIdentificacionRNC"]
                },
            ],
        }
    },
}

const clientSchema = {
    title: "Información Cliente Titular",
    type: "object",
    properties: {
        "nombre": {
            title: "Nombres",
            type: "string",
        },
        "apellidos": {
            title: "Apellidos",
            type: "string",
        },
        "email": {
            title: "Email",
            type: "string",
            format: "email",
        },
        tipoIdentificacionPersonal,
        "sexo": {
            title: "Sexo",
            type: "string",
            oneOf: [
                {
                    title: "Masculino",
                    const: "Masculino"
                },
                {
                    title: "Femenino",
                    const: "Femenino"
                },
            ],
        },
        "estadoCivil": {
            title: "Estado Civil",
            type: "string",
            oneOf: [
                {
                    title: "Soltero",
                    const: "Soltero"
                },
                {
                    title: "Casado",
                    const: "Casado"
                },
                {
                    title: "Unión Libre",
                    const: "Union Libre"
                },
            ],
        },
        "celular": {
            title: "Celular",
            type: "string",
            format: "phone-rd",
        },
        "telefono": {
            title: "Teléfono",
            type: "string",
            format: "phone-rd",
        },
        "otro": {
            title: "Otro",
            type: "string",
            format: "phone-rd",
        },
        "comentarios": leadChannel
    },
    required: ["nombre", "apellidos", "email", "tipoIdentificacionPersonal"
        , "sexo", "estadoCivil",
        "celular"
    ],
    dependencies: {
        "tipoIdentificacionPersonal": {
            oneOf: [
                {
                    properties: {
                        "tipoIdentificacionPersonal": {
                            const: 1,
                        },
                        "numIdentificacionCedula": {
                            title: "No. de identificación Cédula",
                            type: "string",
                        },
                        "buscarDataPersonal":{
                            title:"Buscar datos de usuario por ID",
                            type:"string",
                        },
                    },
                    required: ["numIdentificacionCedula"]
                },
                {
                    properties: {
                        "tipoIdentificacionPersonal": {
                            const: 2,
                        },
                        "numIdentificacionPasaporte": {
                            title: "No. de identificación Pasaporte",
                            type: "string",
                        },
                        "buscarDataPersonal":{
                            title:"Buscar datos de usuario por ID",
                            type:"string",
                        },
                    },
                    required: ["numIdentificacionPasaporte"]
                },
                {
                    properties: {
                        "tipoIdentificacionPersonal": {
                            const: 3,
                        },
                        "numIdentificacionIdExtranjero": {
                            title: "No. de identificación Id Extranjero",
                            type: "string",
                        },
                        "buscarDataPersonal":{
                            title:"Buscar datos de usuario por ID",
                            type:"string",
                        },
                    },
                    required: ["numIdentificacionIdExtranjero"]
                },
            ],
        }
    },
}

form.schema = {
    title: "Solicitud de Vinculación Cliente",
    type: "object",
    properties: {
        clientTypeId,
        "tipoVinculacion": {
            title: "¿Tipo de Vinculación?",
            type: "string",
            oneOf: [
                {
                    title: "Cliente Nuevo JMMB",
                    const: "Cliente Nuevo JMMB"
                },
                {
                    title: "Vinculación a filial adicional",
                    const: "Vinculacion a filial adicional"
                },
                {
                    title: "Cliente existente solo Solicitud de Préstamo",
                    const: "Cliente existente solo Solicitud de Prestamo"
                },
            ],
        },
        "duracionRelacion": {
            title: "Duración de la relación",
            type: "string",
            enumNames: [
                "Cliente Nuevo ( Menos de 1 año)",
                "Más de 1 año menos de 3 años",
                "Más de 3 años menos de 5 años",
                "Más de 5 años"
            ],
            enum: [
                "Cliente Nuevo ( Menos de 1 ano)",
                "Mas de 1 ano menos de 3 anos",
                "Mas de 3 anos menos de 5 anos",
                "Mas de 5 anos"
            ],
        },
        preferredBranchId,
    },
    required: ["clientTypeId", "tipoVinculacion", "preferredBranchId"],
    dependencies: {
        "clientTypeId": {
            oneOf: [
                {
                    properties: {
                        "clientTypeId": { const: 3 }, // Comercial
                        "comercialSchema": comercialSchema,
                    }
                },
                {
                    properties: {
                        "clientTypeId": { const: 2 }, // Pyme
                        "comercialSchema": comercialSchema,
                    }
                },
                {
                    properties: {
                        "clientTypeId": { const: 1 }, // Personal
                        "clientSchema": clientSchema,
                    },
                }
            ]
        }
    }
};


form.uiSchema = {
    "preferredBranchId": { "ui:field": "LookupFormField" },
    "clientTypeId": {
        "ui:widget": "radio",
        "ui:readonly": true,
    },
    "tipoVinculacion": {
        "ui:widget": "radio",
    },
    "comercialSchema": {
        "classNames": "smallTwoRows",
        "ui:order": [
            "nombreEmpresa",
            "actividadPrincipal",
            "nombreContacto",
            "apellidoContacto",
            "posicionContacto",
            "email",
            "tipoIdentificacionComercial",
            "numIdentificacionRNC",
            "numIdentificacionCedula",
            "numIdentificacionPasaporte",
            "numIdentificacionIdExtranjero",
            "buscarDataPersonal",
            "sexo",
            "estadoCivil",
            "celular",
            "telefono",
            "otro",
            "comentarios",
        ],
        "numIdentificacionCedula": {
            "ui:widget": "CedulaWidget",
        },
        "celular": {
            "classNames": "from-col-1",
            "ui:widget": "PhoneWidget",
        },
        "telefono": {
            "ui:widget": "PhoneWidget",
        },
        "otro": {
            "ui:widget": "PhoneWidget",
        },
        "tipoIdentificacionComercial": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "estadoCivilRepresentante": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
    },
    "clientSchema": {
        "classNames": "smallTwoRows",
        "ui:order": [
            "nombre",
            "apellidos",
            "email",
            "tipoIdentificacionPersonal",
            "numIdentificacionCedula",
            "numIdentificacionPasaporte",
            "numIdentificacionIdExtranjero",
            "buscarDataPersonal",
            "sexo",
            "estadoCivil",
            "celular",
            "telefono",
            "otro",
            "comentarios",
        ],
        "numIdentificacionCedula": {
            "ui:widget": "CedulaWidget",
        },
        "celular": {
            "ui:widget": "PhoneWidget",
        },
        "telefono": {
            "ui:widget": "PhoneWidget",
        },
        "otro": {
            "ui:widget": "PhoneWidget",
        },
        "tipoIdentificacionPersonal": {
            "classNames": "from-col-1",
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "buscarDataPersonal":{
            "ui:field": "FetchClientInfoButton",
        },
        "sexo": {
            "classNames": "from-col-1",
            "ui:field": "radio",
            "ui:options": {
                inline: true
            },
        },
        "estadoCivil": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
    },
    "loanComercialSchema": {
        "local": {
            "ui:widget": "radio",
        },
    },
}

form.navigation = [
    {
        title: "Solicitud de Vinculación Cliente",
        id: ""
    },
    {
        title: "Información Inicial Cliente Empresa",
        id: "comercialSchema_"
    },
    {
        title: "Información Inicial Cliente Empresa",
        id: "comercialSchema_"
    },
]


form.objectMap = {
    "clients[0].clientTypeId": "clientTypeId",
    "clients[0].preferredBranchId": "preferredBranchId",
    "clients[0].relationshipType": "tipoVinculacion",
    "clients[$idx].businessClientBasicInfo.businessName": "comercialSchema.nombreEmpresa",
    "clients[$idx].businessClientBasicInfo.businessMainActivity": "comercialSchema.actividadPrincipal",
    "clients[$idx].businessClientBasicInfo.identificationType": "comercialSchema.tipoIdentificacionComercial",
    "clients[$idx].businessClientBasicInfo.legalRepresentativeFirstName": "comercialSchema.nombreContacto",
    "clients[$idx].businessClientBasicInfo.legalRepresentativeLastName": "comercialSchema.apellidoContacto",
    "clients[$idx].businessClientBasicInfo.legalRepresentativePosition": "comercialSchema.posicionContacto",
    "clients[$idx].businessClientBasicInfo.phone": "comercialSchema.telefono",
    "clients[$idx].businessClientBasicInfo.phoneMobile": "comercialSchema.celular",
    "clients[$idx].businessClientBasicInfo.phoneOther": { const: "" },
    "clients[$idx].businessClientBasicInfo.contactEmail": "comercialSchema.email", 
    "clients[$idx].businessClientBasicInfo.comments": "comercialSchema.comentarios",
    // does not exist ::
    "clients[$idx].relationshipType": "tipoVinculacion",
    "clients[$idx].relationshipDuration": "duracionRelacion",
    "clients[$idx].retailClientBasicInfo.firstName": "clientSchema.nombre",
    "clients[$idx].retailClientBasicInfo.lastName": "clientSchema.apellidos",
    "clients[$idx].retailClientBasicInfo.email": "clientSchema.email",
    "clients[$idx].retailClientBasicInfo.identificationType": "clientSchema.tipoIdentificacionPersonal",
    "clients[$idx].retailClientBasicInfo.identificationNumber": "clientSchema.numIdentificacionCedula",
    "clients[$idx].retailClientBasicInfo.passportNumber": "clientSchema.numIdentificacionPasaporte",
    "clients[$idx].retailClientBasicInfo.foreignIdNumber": "clientSchema.numIdentificacionIdExtranjero",
    "clients[$idx].retailClientBasicInfo.sex": "clientSchema.sexo",
    "clients[$idx].retailClientBasicInfo.civilStatus": "clientSchema.estadoCivil",
    "clients[$idx].retailClientBasicInfo.phone": "clientSchema.telefono",
    "clients[$idx].retailClientBasicInfo.phoneMobile": "clientSchema.celular",
    "clients[$idx].retailClientBasicInfo.phoneOther": "clientSchema.otro",

    "clients[$idx].retailClientBasicInfo.comments": "clientSchema.comentarios",
}


export default form;